import {
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  searchSupabaseEntries,
  updateSupabaseEntry
} from './supabaseProxy';

const table = 'resourceGroups';

export const fetchResourceGroup = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const fetchResourceGroups = async ({
  orgId,
  topLevel,
  title,
  //
  groupTags,
  parentId,
  parentIds,
  //
  ids,
  select,
  order = 'title',
  limit = 200
}) => {
  const params = {
    order,
    orgId,
    limit
  };

  if (orgId) {
    params['f.orgId[eq]'] = orgId;
  }

  if (topLevel) {
    params['f.topLevel[eq]'] = topLevel;
  }

  if (title) {
    params['f.title[ilike]'] = title;
  }

  if (parentId !== undefined) {
    if (parentId === null) {
      params['f.parentId[is]'] = parentId;
    } else {
      params['f.parentId[eq]'] = parentId;
    }
  }

  if (Array.isArray(parentIds)) {
    params['f.parentIds[ov]'] = parentIds.join(',');
  }

  if (Array.isArray(groupTags)) {
    params['f.groupTags[ov]'] = groupTags.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchResourceGroupsByTitle = ({
  orgId, title, parentIds
}) => {
  return new Promise((resolve, reject) => {
    fetchResourceGroups({
      parentIds,
      orgId,
      title
    }).then((response) => {
      resolve({
        groups: response?.items
      });
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchResourceGroupsByTags = ({ orgId, groupTags }) => {
  return new Promise((resolve, reject) => {
    fetchResourceGroups({
      orgId,
      groupTags
    }).then((response) => {
      resolve({
        groups: response?.items
      });
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const updateResourceGroup = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    }).then((updatedGroup) => {
      resolve(updatedGroup);
    }).catch((error) => {
      console.error('updateResourceGroup', error);
      reject(error);
    });
  });
};

export const deleteResourceGroup = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    }).then((deletedResource) => {
      resolve(deletedResource);
    }).catch((error) => {
      console.error('deleteResourceGroup', error);
      reject(error);
    });
  });
};

/**
 * @param {String} orgId
 * @param {String} employerId
 */
export const findResourceGroups = ({ title = '', limit = 6 }) => {
  return new Promise((resolve, reject) => {
    searchSupabaseEntries({
      table,
      column: 'title',
      lookup: title,
      limit
    })
      .then((response) => {
        const list = response?.data?.length ? response?.data : null;

        resolve(list);
      })
      .catch((error) => {
        console.error(`find ${table}`, error);
        reject(error);
      });
  });
};
