import { SEARCH_CONTENT_TYPES } from '../constants/globals';
import {
  fetchResourceGroups,
  fetchResourceGroupsByTitle
  // fetchResourceGroupsByTags
} from '../services/resourceGroups';
import { fetchResourcesByBody, fetchResourcesByName, fetchResourcesByVectorText } from '../services/resources';
import {
  fetchUsersById,
  fetchUsersByEmail,
  fetchUsersByName,
  fetchUsersByTitle
} from '../services/users';
import {
  fetchProjectsByBody,
  fetchProjectsByTitle
} from '../services/projects';
import uniqueObjectArray from '../utils/uniqueObjectArray';
import {
  RESET_SEARCH, SET_ENTRY_ORG_ID, SET_SEARCH, SET_SEARCH_FETCHING, SET_SEARCH_USER_FEEDBACK, SET_SEARCH_VALUE
} from './types';
import { fetchCoursesBySearchValue } from '../services/courses';
import uniqueStringArray from '../utils/uniqueStringArray';

export const setSearchValue = ({ searchValue, searchGroupIds }) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_SEARCH_VALUE,
        value: searchValue,
        ...(searchGroupIds ? { searchGroupIds } : {})
      });
      resolve();
    });
  };
};

const prepQueryForILike = (string) => {
  const result = string.replaceAll(' ', '%');
  return `%${result}%`;
};

export const searchTurbine = ({
  orgId,
  locationId,
  userId,
  searchValue,
  searchGroupIds = null,
  resourceGroupTags,
  contentTypes = SEARCH_CONTENT_TYPES,
  excludeInactive, //  = SEARCH_ADVANCED_FILTERS.excludeInactive
  excludeTestUsers, // SEARCH_ADVANCED_FILTERS.excludeTestUsers
  saveSearch = false
}) => {
  return (dispatch) => {
    dispatch({
      type: SET_SEARCH_FETCHING,
      fetching: true
    });

    return new Promise((resolve, reject) => {
      const tags = [];
      let cdnFiles = []; // resources
      let vectorSearchId = null;
      let resourceVecIds = null;
      let vectorFiles = []; // resources + embeddings
      let vectorNodes = []; // embeddings
      let allGroups = []; // resourceGroups
      let allUsers = [];
      let allProjects = [];
      let allCourses = [];

      if (searchValue && Array.isArray(resourceGroupTags.list) && resourceGroupTags.list.length > 0) {
        resourceGroupTags.list.forEach((resourceGroupTag) => {
          if (resourceGroupTag?.label && resourceGroupTag?.label?.toLowerCase().includes(searchValue.toLowerCase())) {
            tags.push(resourceGroupTag);
          }
        });
      }
      const searchValueSpaces = (searchValue || '').trim().match(/([\s]+)/g);
      const numSearchValueSpaces = Array.isArray(searchValueSpaces) ? searchValueSpaces.length : 0;

      const searchValueILike = prepQueryForILike(searchValue);
      const searchValueLength = (searchValue || '').length;
      const performUserIdSearch = searchValueLength > 20 && numSearchValueSpaces === 0;
      const valueIsPrompt = numSearchValueSpaces >= 4;

      // const groupTags = tags.length ? tags.map((groupTag) => groupTag.id) : null;

      const resourceArgs = {
        orgId,
        ...(searchGroupIds ? { parentIds: searchGroupIds } : {}),
        parentType: 'resourceGroup'
      };

      const resourceVectorArgs = {
        ...resourceArgs,
        userId,
        locationId,
        saveSearch,
        contentTypes,
        searchText: searchValue
      };

      Promise.all([
        // ...(groupTags ? [fetchResourceGroupsByTags({ orgId, groupTags })] : []),
        ...(contentTypes.includes('resources') && valueIsPrompt ? [
          fetchResourcesByVectorText(resourceVectorArgs)
        ] : []),
        ...(contentTypes.includes('resources') ? [fetchResourceGroupsByTitle({ ...resourceArgs, title: searchValue })] : []),
        ...(contentTypes.includes('resources') ? [fetchResourcesByName({ ...resourceArgs, name: searchValue })] : []),
        ...(contentTypes.includes('resources') ? [fetchResourcesByBody({ ...resourceArgs, body: searchValue })] : []),
        // Users
        ...(contentTypes.includes('users') && performUserIdSearch ? [
          fetchUsersById({
            orgId, userIds: [searchValue], excludeInactive, excludeTestUsers
          })
        ] : []),
        ...(contentTypes.includes('users') ? [
          fetchUsersByName({
            orgId, name: searchValueILike, excludeInactive, excludeTestUsers
          })
        ] : []),
        ...(contentTypes.includes('users') ? [
          fetchUsersByTitle({
            orgId, title: searchValueILike, excludeInactive, excludeTestUsers
          })
        ] : []),
        ...(contentTypes.includes('users') ? [
          fetchUsersByEmail({
            orgId, email: searchValue, excludeInactive, excludeTestUsers
          })
        ] : []),
        // Projects
        ...(contentTypes.includes('projects') ? [
          fetchProjectsByTitle({
            orgId, title: searchValueILike
          })
        ] : []),
        ...(contentTypes.includes('projects') ? [
          fetchProjectsByBody({
            orgId, body: searchValue
          })
          // Courses
        ] : []),
        ...(contentTypes.includes('courses') ? [
          fetchCoursesBySearchValue({
            orgId, title: searchValue
          })
        ] : []),
        ...(contentTypes.includes('courses') ? [
          fetchCoursesBySearchValue({
            orgId, overview: searchValue
          })
        ] : [])
      ]).then((responseValues) => {
        responseValues.forEach((responseValue) => {
          if (responseValue.groups) {
            allGroups = [
              ...allGroups,
              ...responseValue.groups
            ];
          }

          if (responseValue.cdnFiles) {
            cdnFiles = [
              ...cdnFiles,
              ...responseValue.cdnFiles
            ];
          }

          if (responseValue.resourceVecIds) {
            resourceVecIds = responseValue.resourceVecIds;
          }

          if (responseValue.vectorSearchId) {
            vectorSearchId = responseValue.vectorSearchId;
          }

          if (responseValue.vectorFiles) {
            vectorFiles = [
              ...vectorFiles,
              ...responseValue.vectorFiles
            ];
          }

          if (responseValue.vectorNodes) {
            vectorNodes = [
              ...vectorNodes,
              ...responseValue.vectorNodes
            ];
          }

          if (responseValue.users) {
            allUsers = [
              ...allUsers,
              ...responseValue.users
            ];
          }

          if (responseValue.projects) {
            allProjects = [
              ...allProjects,
              ...responseValue.projects
            ];
          }

          if (responseValue.courses) {
            allCourses = [
              ...allCourses,
              ...responseValue.courses
            ];
          }
        });

        const data = {
          groups: allGroups.length ? uniqueObjectArray(allGroups) : null,
          vectorSearchId: vectorSearchId || null,
          vectorFiles: vectorFiles.length ? uniqueObjectArray(vectorFiles) : null,
          vectorNodes: vectorNodes.length ? uniqueObjectArray(vectorNodes) : null,
          resourceVecIds: resourceVecIds || null,
          resourceGroupIds: null,
          resourceGroupsHash: null,
          cdnFiles: cdnFiles.length ? uniqueObjectArray(cdnFiles) : null,
          users: allUsers.length ? uniqueObjectArray(allUsers) : null,
          projects: allProjects.length ? uniqueObjectArray(allProjects) : null,
          courses: allCourses.length ? uniqueObjectArray(allCourses) : null,
          tags: tags.length ? tags : null,
          value: searchValue,
          valueIsPrompt
        };

        const hasVectorFiles = Array.isArray(data.vectorFiles) && data.vectorFiles.length > 0;
        const hasCdnFiles = Array.isArray(data.cdnFiles) && data.cdnFiles.length > 0;
        const hasFiles = hasVectorFiles || hasCdnFiles;

        if (hasFiles) {
          // Fetch Resource Groups to display Folder Names
          // - Collate all resources sources (vectorFiles & cdnFiles)
          // - Create an array of unique parentIds to fetch Resource Groups
          data.resourceGroupIds = [];

          if (hasCdnFiles) {
            const cdnFileParentIds = data.cdnFiles.map((file) => file?.parentId);

            data.resourceGroupIds = [
              ...data.resourceGroupIds,
              ...cdnFileParentIds
            ];
          }

          if (hasVectorFiles) {
            const vectorFileParentIds = data.vectorFiles.map((file) => file?.parentId);

            data.resourceGroupIds = [
              ...data.resourceGroupIds,
              ...vectorFileParentIds
            ];
          }

          data.resourceGroupIds = data.resourceGroupIds.length ? uniqueStringArray(data.resourceGroupIds) : null;

          fetchResourceGroups({ orgId, ids: data.resourceGroupIds }).then((response) => {
            const list = Array.isArray(response?.items) ? response.items : null;
            // ORG CHECK
            dispatch({
              type: SET_ENTRY_ORG_ID,
              entryOrgId: list && list[0] ? list[0]?.orgId : null
            });

            if (list) {
              data.resourceGroupsHash = list.reduce((acc, curr) => {
                const result = { ...acc };
                result[curr.id] = curr;
                return result;
              }, {});
            }

            dispatch({
              type: SET_SEARCH,
              ...data
            });
            resolve(data);
          });
        } else {
          dispatch({
            type: SET_SEARCH,
            ...data
          });
          resolve(data);
        }
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const setSearchUserFeedback = ({ userFeedback }) => {
  return (dispatch) => {
    dispatch({
      type: SET_SEARCH_USER_FEEDBACK,
      userFeedback
    });
  };
};

export const resetSearch = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SEARCH
    });
  };
};
