import {
  createSupabaseEntry, fetchSupabaseEntries, fetchSupabaseEntry, updateSupabaseEntry
} from './supabaseProxy';

const table = 'userProfiles';

/**
 * @param {String} orgId
 * @param {String} employerId
 */
export const fetchUserProfiles = async ({
  ids = [],
  orgId,
  select
}) => {
  const params = {
    orgId
  };

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const rsp = await fetchSupabaseEntries(params, table);
  const userProfiles = Array.isArray(rsp?.items) && rsp?.items.length ? rsp?.items : null;

  return userProfiles;
};

export const fetchUserProfile = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateUserProfile = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`update ${table}`, error);
      reject(error);
    });
  });
};

export const createUserProfile = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`create ${table}`, error);
      reject(error);
    });
  });
};
