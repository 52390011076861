import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FaCog, FaEllipsisH, FaPlus
} from 'react-icons/fa';
import { canManageApprenticeship, canManageInstructionProvider } from '../../../permissions/manage';
import { canCreateNoteInstructionProvider } from '../../../permissions/create';
import { setCurrentModal } from '../../../actions/Modals';
import { createMaterial } from '../../../services/materials';
import { getMaterial, getNoteMaterials } from '../../../actions/Materials';
import { setSideSheet } from '../../../actions/SideSheets';
import { SHEET_KEY_MATERIAL } from '../../../constants/sideSheets';

const DotMenu = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const instructionProvider = useSelector((state) => state.instructionProvider);
  const organization = useSelector((state) => state.organization);
  const noteApprenticeshipMaterials = useSelector((state) => state.noteApprenticeshipMaterials);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const role = currentUser?.role || [];
  // Permissions
  const manageApprenticeship = canManageApprenticeship(role);

  const hasInstructionProviderMaterials = useMemo(() => {
    return Array.isArray(noteApprenticeshipMaterials?.list) && noteApprenticeshipMaterials.list.length > 0;
  }, [noteApprenticeshipMaterials.list]);

  if (!manageApprenticeship) {
    return null;
  }

  const editInstructionProvider = () => {
    dispatch(setCurrentModal({
      key: 'manageInstructionProvider',
      data: {
        instructionProviderId: instructionProvider?.id
      }
    }));
  };

  const createNote = () => {
    const noteType = 'instructionProvider';
    const noteTypeId = instructionProvider?.id;

    if (!noteTypeId) {
      console.error('Missing required argument, noteTypeId');
      return;
    }

    const dataToSave = {
      title: `Internal Note: ${instructionProvider?.name}`,
      body: '',
      orgId,
      noteInstructionProviderId: noteTypeId,
      userId: currentUser?.id,
      enableNewEditor: true
    };

    createMaterial(dataToSave).then((response) => {
      setTimeout(() => {
        // Wait to make sure Material is created
        // a race condition was observed without timeout

        dispatch(getMaterial(response?.id)).then(() => {
          dispatch(setSideSheet({
            key: SHEET_KEY_MATERIAL,
            className: 'MaterialSideSheet z-index-100',
            data: {
              editMode: true,
              tinyMceEditor: {
                uploadPdfButton: false,
                embedMediaButton: false,
                uploadImgButton: false
              },
              noteType,
              noteTypeId
            },
            callbackPrimaryAction: () => {
              if (instructionProvider?.id) {
                dispatch(getNoteMaterials({
                  noteType,
                  noteTypeId,
                  orgId
                }));
              }
            }
          }));
        });
      }, 250);
    });
  };

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-sm btn-white rounded text-muted"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />
          {canCreateNoteInstructionProvider(role) && !hasInstructionProviderMaterials && (
            <button
              className="dropdown-item border-bottom py-2 px-3"
              onClick={createNote}
              type='button'
            >
              <span className='d-flex align-items-center'>
                <FaPlus />
                <span className='ml-2'>
                  Internal Note
                </span>
              </span>
            </button>
          )}

          {canManageInstructionProvider(role) && (
            <button
              type="button"
              title='Edit'
              className="dropdown-item px-3 py-2"
              onClick={editInstructionProvider}
            >
              <span className='d-flex align-items-center'>
                <FaCog />
                <span className='ml-2'>
                  Settings
                </span>
              </span>
            </button>
          )}

        </div>
      </div>
    </div>
  );
};

export default DotMenu;
