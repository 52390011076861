import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaMagnifyingGlassChart } from 'react-icons/fa6';
import { FaChevronLeft } from 'react-icons/fa';
import { mockRawText } from '../../../constants/mockRawText';
import { getFullAddress } from '../../../services/locations';
import { fetchResourcesByVectorText } from '../../../services/resources';
import { chatEngine } from '../../../services/openAi';
import { updateSideSheet } from '../../../actions/SideSheets';
import { createUserPrompt, updateUserPrompt } from '../../../services/userPrompts';
import DotMenu from './DotMenu';
import TitleScreen from './TitleScreen';
import SearchVelaResponse from '../../SearchContent/SearchVelaResponse';
import SearchVectorResults from '../../SearchContent/SearchVectorResults';
import SearchResultsSkeleton from '../../SearchContent/SearchResultsSkeleton';
import Header from './Header';
import JobInfo from './JobInfo';
import '../../../styles/scanningAnimation.css';
import './style.css';
import ManualOrAutoInput from './ManualOrAutoInput';
import { cheerioParseJobDescription } from '../../../services/cheerio';

const ResumeScreenerSideSheet = ({ className = '' }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const userLocation = useSelector((state) => state.userLocation);
  const sideSheet = useSelector((state) => state.sideSheet);
  const resourceGroup = useSelector((state) => state.resourceGroup);
  // Current User
  const userId = currentUser?.id || null;
  const locationId = currentUser?.locationId || null;
  // Organization
  const orgId = organization?.id || null;
  const orgDescription = organization?.description || null;
  // Side Sheet
  const sideSheetData = sideSheet?.data || null;
  const resourceGroupIds = sideSheetData?.resourceGroupIds || null;
  const resourceGroupTitle = sideSheetData?.resourceGroupTitle || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // Animation
  const [showScanningAnimation, setShowScanningAnimation] = useState(false);
  const [animationActionStep, setAnimationActionStep] = useState('loading');
  const [rawText, setRawText] = useState(mockRawText);

  const hasFiles = useMemo(() => {
    return Array.isArray(resourceGroup?.cdnFiles) && resourceGroup?.cdnFiles.length > 0;
  }, [resourceGroup?.cdnFiles]);

  const embeddedFiles = useMemo(() => {
    let files = [];

    if (hasFiles) {
      files = resourceGroup?.cdnFiles.filter((item) => item.isEmbedded);
    }

    return files;
  }, [hasFiles, resourceGroup?.cdnFiles]);

  const hasEmbeddedFiles = useMemo(() => {
    return Array.isArray(embeddedFiles) && embeddedFiles.length > 0;
  }, [embeddedFiles]);

  // Form Fields
  const defaultNumCandidates = 5;

  const defaultCandidates = useMemo(() => {
    let candidates = defaultNumCandidates;

    if (embeddedFiles.length <= defaultNumCandidates) {
      candidates = embeddedFiles.length - 1;
    }

    if (embeddedFiles.length === 1) {
      candidates = 1;
    }

    return candidates;
  }, [embeddedFiles.length]);

  const [currentScreen, setCurrentScreen] = useState('title');
  const [publicUrl, setPublicUrl] = useState(null);
  const [numCandidates, setNumCandidates] = useState(defaultCandidates);
  const [jobLocation, setJobLocation] = useState(getFullAddress({ location: userLocation }));
  const [jobLocationRemote, setJobLocationRemote] = useState(false);
  const [companyDescription, setCompanyDescription] = useState(orgDescription || '');
  const [jobTitle, setJobTitle] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [responsibilities, setResponsibilities] = useState('');
  const [qualifications, setQualifications] = useState('');
  const [vectorAnswer, setVectorAnswer] = useState('');
  const [vectorAnswerDone, setVectorAnswerDone] = useState(false);
  const [vectorFiles, setSearchVectorFiles] = useState('');
  const [vectorNodes, setSearchVectorNodes] = useState('');

  const handlePrompt = async ({ prompt, resourceVecIds, userPromptId }) => {
    const response = await chatEngine({
      orgId,
      prompt,
      promptType: 'resumeScreener',
      resourceVecIds,
      streaming: true
    });

    setCurrentScreen('response');

    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .getReader();

    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const { value, done } = await reader.read();
      if (done) {
        // TODO Save updatePrompt .then(() => {})
        setVectorAnswerDone(true);
        if (userPromptId) {
          updateUserPrompt({
            output: vectorAnswer,
            outputType: null,
            outputError: null
          }, userPromptId);
        }
        break;
      }
      setVectorAnswer((prev) => prev + value);
    }
  };

  const saveUserPrompt = async (promptText, promptType, resourceIds) => {
    return await createUserPrompt({
      orgId,
      locationId,
      userId,
      resourceIds: Array.isArray(resourceIds) && resourceIds.length > 0 ? resourceIds : null,
      input: promptText,
      inputType: promptType
    });
  };

  const runSearch = (promptText) => {
    setVectorAnswer('');

    fetchResourcesByVectorText({
      userId,
      orgId,
      locationId,
      saveSearch: false,
      parentIds: resourceGroupIds,
      parentType: 'resourceGroup',
      contentTypes: 'resources',
      searchText: promptText,
      ...(numCandidates > 5 ? { count: numCandidates } : {})
    }).then((searchResponse) => {
      setLoading(false);
      // searchResponse: { vectorSearchId, vectorFiles, vectorFileIds, vectorNodes, resourceVecIds }
      setSearchVectorFiles(searchResponse?.vectorFiles);
      setSearchVectorNodes(searchResponse?.vectorNodes);

      let nodesRawText = [];

      if (Array.isArray(searchResponse?.vectorNodes) && searchResponse?.vectorNodes.length > 0) {
        nodesRawText = searchResponse?.vectorNodes.slice(0, 5);
        searchResponse?.vectorNodes.forEach((n) => {
          nodesRawText.push(n.rawText);
        });
        setRawText(nodesRawText.join(' '));
      }

      if (searchResponse?.resourceVecIds && promptText) {
        setAnimationActionStep('scanning');
        saveUserPrompt(promptText, 'resumeScreener', searchResponse?.vectorFileIds).then(async (responseUserPrompt) => {
          try {
            await handlePrompt({
              orgId,
              prompt: promptText,
              resourceVecIds: searchResponse?.resourceVecIds,
              userPromptId: responseUserPrompt?.id
            });
          } catch (error) {
            toast.error('We\'ve been notified that something went wrong, please try again.');
            updateUserPrompt({
              outputError: error?.message || null
            }, responseUserPrompt?.id);
          } finally {
            setShowScanningAnimation(false);
          }
        });
      } else {
        toast.error('Something went wrong, refresh and try again.', { autoClose: false });
      }
    });
  };

  const goToJobInfoScreen = () => {
    dispatch(updateSideSheet({
      className: 'MaterialSideSheet card-style'
    }));
    setCurrentScreen('jobInfo');
  };

  const goToManualOrAutoInputScreen = () => {
    setCurrentScreen('manualOrAutoInput');
  };

  const onClickParseJobDescription = () => {
    if (!publicUrl) {
      toast.error('URL is required');
      return;
    }
    setLoading(true);
    cheerioParseJobDescription(publicUrl).then((response) => {
      console.log('response', response);

      if (response.error) {
        toast.info('URL unreadable. Manually enter Job Details', { autoClose: false });
        return;
      }

      if (response?.jobTitle !== undefined) {
        setJobTitle(response?.jobTitle);
      }

      if (response?.jobDescription !== undefined) {
        setJobDescription(response?.jobDescription);
      }

      if (response?.responsibilities !== undefined) {
        setResponsibilities(response?.responsibilities);
      }

      if (response?.qualifications !== undefined) {
        setQualifications(response?.qualifications);
      }

      if (Array.isArray(response?.locations)) {
        setJobLocation(response?.locations.join(', '));
      }

      if (response?.isRemoteJob) {
        setJobLocationRemote(response?.isRemoteJob);
      }

      if (response?.companyDescription !== undefined) {
        setCompanyDescription(response?.companyDescription);
      }
    }).catch((error) => {
      toast.warn('URL unreadable. Manually enter Job Details', { autoClose: false });
      console.error(error);
    }).finally(() => {
      goToJobInfoScreen();
      setLoading(false);
    });
  };

  const onClickAnalyzeResumes = () => {
    const candidates = parseInt(numCandidates, 10);

    let promptText = `Find and return ${candidates} Candidate${candidates > 1 ? 's' : ''} with the required experience to best perform the JOB REQUIREMENTS for the vacant JOB POSITION: "${jobTitle || ''}" by meticulously reviewing Candidate Resumes and aligning their experience and qualifications with the specific JOB REQUIREMENTS.`;
    promptText += '\n Only if none of the candidates meet the JOB REQUIREMENTS, explain how you came to that conclusion.';

    promptText += '\n DO NOT return Candidates that do NOT have the required experience to adequately perform the JOB REQUIREMENTS.';

    if (candidates === 1 && candidates === embeddedFiles.length) {
      promptText = `Determine if the candidate is match for our vacant JOB POSITION: "${jobTitle || ''}". By meticulously reviewing their resume and aligning their qualifications with the specific JOB REQUIREMENTS.`;
      promptText += '\n If the candidate does not meet the JOB REQUIREMENTS, explain how you came to that conclusion.';
    }

    if (!jobTitle) {
      toast.error('Job title is required.');
      return;
    }

    if (!jobDescription) {
      toast.error('Job description is required.');
      return;
    }

    if (!jobLocationRemote && !jobLocation) {
      toast.error('Job Location is required if job is NOT remote.');
      return;
    }

    promptText += `\n JOB REQUIREMENTS consists of JOB POSITION, JOB DESCRIPTION, COMPANY DESCRIPTION, ESSENTIAL JOB FUNCTIONS, ${!jobLocationRemote && jobLocation ? 'JOB LOCATION ' : ''}and JOB QUALIFICATIONS.`;

    setLoading(true);
    setShowScanningAnimation(true);

    promptText += '\n For each qualified candidate include a "JobMatch" score (a percentage (1-100%) indicating how well their resume matches the vacant JOB POSITION.';

    // if (jobSkills) {
    //   promptText += "Prioritize the following skills or similar: Record Keeping, Office/Admin Management, Project Management, Data Entry, Microsoft Office Suite (MS), Financial Management."
    // }

    if (!jobLocationRemote && jobLocation) {
      promptText += `\n JOB LOCATION: Candidates will need to reside near ${jobLocation} to ensure work site flexibility.`;
    }

    if (jobDescription) {
      promptText += `\n JOB DESCRIPTION: ${jobDescription}`;
    }

    if (companyDescription) {
      promptText += `\n COMPANY DESCRIPTION: ${companyDescription}`;
    }

    if (responsibilities) {
      promptText += `\n ESSENTIAL JOB FUNCTIONS: ${responsibilities}`;
    }

    if (qualifications) {
      promptText += `\n JOB QUALIFICATIONS: ${qualifications}`;
    }

    runSearch(promptText);
  };

  return (
    <div className={`card ${className}`}>
      <div className='card-header d-flex align-items-center justify-content-between'>
        <h6 className="m-0 font-weight-bold">
          <span className="d-flex align-items-center">
            <FaMagnifyingGlassChart />
            <span className="ml-2 text-nowrap">
              Resume Screener
            </span>
            <span className='badge badge-dark ml-2'>
              beta
            </span>
          </span>
        </h6>
        <DotMenu
          editMode={editMode}
          setEditMode={setEditMode}
        />
      </div>

      {currentScreen === 'title' && (
        <TitleScreen
          resourceGroupTitle={resourceGroupTitle}
          hasFiles={hasFiles}
          embeddedFiles={embeddedFiles}
          hasEmbeddedFiles={hasEmbeddedFiles}
        />
      )}

      {currentScreen === 'manualOrAutoInput' && (
        <ManualOrAutoInput
          loading={loading}
          setCurrentScreen={setCurrentScreen}
          publicUrl={publicUrl}
          setPublicUrl={setPublicUrl}
          resourceGroupTitle={resourceGroupTitle}
          hasEmbeddedFiles={hasEmbeddedFiles}
          embeddedFiles={embeddedFiles}
          hasFiles={hasFiles}
        />
      )}

      {currentScreen === 'response' && (
        <div className='card-body overflow-y-scroll'>
          <Header
            jobTitle={jobTitle}
            numCandidates={numCandidates}
            resourceGroupTitle={resourceGroupTitle}
            hasFiles={hasFiles}
            embeddedFiles={embeddedFiles}
            hasEmbeddedFiles={hasEmbeddedFiles}
          />

          <div className="my-3">
            <SearchVelaResponse
              vectorAnswer={vectorAnswer || null}
              vectorSearchId={null}
              materialId={null}
            />
          </div>

          {Array.isArray(vectorFiles) && vectorFiles.length !== 0 ? (
            <SearchVectorResults
              title="Resumes"
              vectorSearchId={null}
              vectorFiles={vectorFiles}
              vectorNodes={vectorNodes}
            />
          ) : (
            <SearchResultsSkeleton />
          )}
        </div>
      )}

      {currentScreen === 'jobInfo' && (
        <JobInfo
          resourceGroupTitle={resourceGroupTitle}
          rawText={rawText}
          showScanningAnimation={showScanningAnimation}
          animationActionStep={animationActionStep}
          hasEmbeddedFiles={hasEmbeddedFiles}
          embeddedFiles={embeddedFiles}
          defaultNumCandidates={defaultNumCandidates}
          //
          jobLocationRemote={jobLocationRemote}
          setJobLocationRemote={setJobLocationRemote}
          //
          numCandidates={numCandidates}
          setNumCandidates={setNumCandidates}
          //
          jobTitle={jobTitle}
          setJobTitle={setJobTitle}
          //
          jobLocation={jobLocation}
          setJobLocation={setJobLocation}
          //
          companyDescription={companyDescription}
          setCompanyDescription={setCompanyDescription}
          //
          jobDescription={jobDescription}
          setJobDescription={setJobDescription}
          //
          responsibilities={responsibilities}
          setResponsibilities={setResponsibilities}
          //
          qualifications={qualifications}
          setQualifications={setQualifications}
        />
      )}

      <div
        className={`card-footer d-flex ${currentScreen === 'response' || currentScreen === 'jobInfo' ? 'justify-content-between' : 'justify-content-end'}`}
      >
        {currentScreen === 'title' && (
          <button
            type='button'
            className='btn btn-primary'
            disabled={!hasFiles || !hasEmbeddedFiles}
            onClick={() => {
              setCurrentScreen('manualOrAutoInput'); // jobInfo
            }}
          >
            Get Started
          </button>
        )}

        {currentScreen === 'manualOrAutoInput' && (
          <>
            <button
              type='button'
              className='btn btn-primary'
              disabled={!publicUrl || loading}
              onClick={onClickParseJobDescription}
            >
              Next
            </button>
          </>
        )}

        {currentScreen === 'jobInfo' && (
          <>
            <button
              type="button"
              onClick={goToManualOrAutoInputScreen}
              className='btn btn-outline-primary'
              disabled={loading}
            >
              <span className='d-flex align-items-center'>
                <FaChevronLeft className='mr-1' />
                Back
              </span>
            </button>

            <button
              type='button'
              className='btn btn-primary'
              disabled={loading}
              onClick={onClickAnalyzeResumes}
            >
              {loading ? 'Analyzing...' : 'Analyze Resumes'}
            </button>
          </>
        )}

        {currentScreen === 'response' && (
          <button
            className='btn btn-outline-primary'
            disabled={loading || !vectorAnswerDone}
            onClick={goToJobInfoScreen}
            type='button'
          >
            <span className='d-flex align-items-center'>
              <FaChevronLeft className='mr-1' />
              Job Information
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default ResumeScreenerSideSheet;
